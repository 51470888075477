<template>
    <div>
        <div class="commonInputTopBox">
            <pageHeader :pagename="'故障类目管理'" :line="true" />
            <CommonHeaders
                :formConfig="formConfig"
                :formModel="formModel"
                :buttons="buttons"
                @button-click="handleButtonClick"
            ></CommonHeaders>
        </div>
        <div class="commonControl-body">
            <common-table
                :tableData="tableData"
                :tableButton="tableButton"
                :tableLabel="tableLabel"
                @edit="editFirstLevel"
            ></common-table>
            <div class="layoutBorder">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="[5, 10, 20, 40]"
                    :page-size="pagesize"
                    layout="total, sizes, prev, pager, next, jumper"
                >
                </el-pagination>
            </div>
        </div>

        <!-- 点击事件触发 -->
        <el-dialog title="编辑故障分类" :visible.sync="dialogFormVisibleEdit">
            <el-form :model="formEdit">
                <el-form-item label="故障分类id" label-width="120px">
                    <el-input
                        v-model="formEdit.type_id"
                        autocomplete="off"
                        :disabled="true"
                    ></el-input>
                </el-form-item>
                <el-form-item label="故障类型名" label-width="120px">
                    <el-input
                        v-model="formEdit.type_name"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisibleEdit = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="updateFirstLevel()"
                    >确 定</el-button
                >
            </div>
        </el-dialog>

        <el-dialog title="新增故障类型" :visible.sync="dialogFormVisibleAdd">
            <el-form :model="formAdd">
                <el-form-item label="故障类型名" label-width="120px">
                    <el-input
                        v-model="formAdd.type_name"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisibleAdd = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="createFirstLevel()"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    equipmentFaultManageQuestionTypeQuery,
    equipmentFaultManageQuestionTypeCreate,
    equipmentFaultManageQuestionTypeUpdate,
    equipmentFaultManageQuestionTypeDelete
} from '@/api/manage/operation/equipment_fault_manage.js';
import CommonTable from 'frontelementpackage/src/CommonTableButtonFixed.vue';
import CommonHeaders from '@/components/shibo_Common/CommonHeaders.vue';

export default {
    name: 'start',
    data() {
        return {
            query: {
                querypage: 1, //获取页码
                pagesize: 10, //每页返回数量
                keyword: '' //搜索 可搜邮箱 用户名 手机号
            },
            //分页数据
            total: 0,
            currentPage: 1, //初始页
            pagesize: 10, //    每页的数据
            tableData: [],
            dialogFormVisibleEdit: false,
            formEdit: {},
            dialogFormVisibleAdd: false,
            formAdd: {},
            tableLabel: [
                {
                    prop: 'id',
                    label: 'id',
                    minWidth: '50%'
                },
                {
                    prop: 'type_name',
                    label: '类目名',
                    minWidth: '50%'
                }
            ],
            tableButton: {
                width: 200,
                data: [
                    {
                        name: '编辑',
                        type: 'primary',
                        size: 'small'
                    },
                    {
                        name: '删除',
                        type: 'danger',
                        size: 'small'
                    }
                ]
            },
            formConfig: [],
            formModel: {},
            buttons: [
                { label: '新增故障分类', type: 'primary', action: 'create' }
            ]
        };
    },
    components: {
        CommonTable,
        CommonHeaders
    },
    created() {
        this.getFirstLevel();
    },
    methods: {
        editFirstLevel(row, methods) {
            if (methods == '编辑') {
                this.editUser(row);
            }
            if (methods == '删除') {
                this.delUser(row);
            }
        },
        editUser(row) {
            this.formEdit = {
                type_id: row.id,
                type_name: row.product_type_name
            };
            console.log(this.form, 'ddd');
            this.dialogFormVisibleEdit = true;
        },
        delUser(row) {
            let ids = row.id;

            this.$confirm('是否确定删除', '确认信息', {
                distinguishCancelAndClose: true,
                confirmButtonText: '删除',
                cancelButtonText: '放弃删除'
            })
                .then(() => {
                    equipmentFaultManageQuestionTypeDelete({
                        type_id: ids
                    }).then(res => {
                        if (res.code == 200) {
                            this.$message.success('成功删除');
                            this.$router.go(0);
                        } else {
                            this.$message.info(res.msg);
                        }
                    });
                })
                .catch(action => {
                    this.$message({
                        type: 'info',
                        message: action === 'cancel' ? '放弃删除' : '停留在当前'
                    });
                });
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.query = {
                querypage: this.currentPage,
                pagesize: this.pagesize,
                keyword: ''
            };
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.query = {
                querypage: this.currentPage,
                pagesize: this.pagesize,
                keyword: ''
            };
            console.log(this.query);
        },

        createFirstLevel() {
            equipmentFaultManageQuestionTypeCreate(this.formAdd).then(res => {
                if (res.code == 200) {
                  this.$router.go(0);
                } else {
                    this.$message.info(res.msg);
                }
            });
            this.dialogFormVisibleAdd = false;
        },
        addFormButton() {
            this.dialogFormVisibleAdd = true;
        },
        updateFirstLevel() {
            console.log(this.formEdit);
            equipmentFaultManageQuestionTypeUpdate(this.formEdit).then(res => {
                if (res.code == 200) {
                    this.$router.go(0);
                } else {
                    this.$message.info(res.msg);
                }
            });
            this.dialogFormVisibleEdit = false;
        },
        getFirstLevel() {
            equipmentFaultManageQuestionTypeQuery({}).then(res => {
                console.log(this.tableData, 'new');
                this.tableData = res.data;
                this.total = res.data.total;
            });
        },
        handleButtonClick(button) {
            if (button.action === 'create') {
                this.addFormButton();
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
